import {
  PermissionListViewModel_default
} from "./chunk-3DKAWVGO.js";
import {
  PermissionDetailViewModel_default
} from "./chunk-364LXEWW.js";
import {
  __publicField
} from "./chunk-JCO6WRHX.js";

// src/pages/Permission/PermissionViewModel/PermissionViewModel.ts
var PermissionViewModel = class {
  constructor(permissionStore) {
    __publicField(this, "permissionDetailViewModel", {});
    __publicField(this, "permissionListViewModel", {});
    __publicField(this, "getPermissionDetailViewModel", () => this.permissionDetailViewModel);
    __publicField(this, "getPermissionListViewModel", () => this.permissionListViewModel);
    if (permissionStore) {
      this.permissionDetailViewModel = new PermissionDetailViewModel_default(permissionStore);
      this.permissionListViewModel = new PermissionListViewModel_default(permissionStore);
    }
  }
};
var PermissionViewModel_default = PermissionViewModel;

export {
  PermissionViewModel_default
};
/*
 * @copyright   Copyright (C) 2022 AesirX. All rights reserved.
 * @license     GNU General Public License version 3, see LICENSE.
 */

import {
  withPermissionViewModel
} from "./chunk-BH5BVBWY.js";
import {
  A
} from "./chunk-4COC2UV3.js";
import {
  AesirXSelectExtended
} from "./chunk-HMAYFD5S.js";
import {
  Spinner
} from "./chunk-POLRV4GK.js";

// src/pages/Permission/ListPermission.tsx
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation, withTranslation } from "react-i18next";
import { observer } from "mobx-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMinusSquare, faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { PERMISSION_FIELD } from "aesirx-lib";
var ListPermission = observer((props) => {
  const { t } = useTranslation();
  const viewModel = props.model.permissionListViewModel;
  const [loadingUpdate, setLoadingUpdate] = useState({});
  useEffect(() => {
    viewModel.initializeData();
  }, []);
  const columnsTable = [
    {
      id: "expander",
      width: 50,
      Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => /* @__PURE__ */ React.createElement("span", { ...getToggleAllRowsExpandedProps() }, isAllRowsExpanded ? /* @__PURE__ */ React.createElement("i", { className: "ms-2 fs-5 text-success" }, /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon: faMinusSquare })) : /* @__PURE__ */ React.createElement("i", { className: "ms-2 fs-5 text-success" }, /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon: faPlusSquare }))),
      Cell: ({ row }) => row.canExpand ? /* @__PURE__ */ React.createElement(
        "span",
        {
          ...row.getToggleRowExpandedProps({
            style: {
              paddingLeft: `${row.depth * 2}rem`
            }
          })
        },
        row.isExpanded ? /* @__PURE__ */ React.createElement("i", { className: "ms-2 fs-5 text-success" }, /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon: faMinusSquare })) : /* @__PURE__ */ React.createElement("i", { className: "ms-2 fs-5 text-success" }, /* @__PURE__ */ React.createElement(FontAwesomeIcon, { icon: faPlusSquare }))
      ) : null
    },
    {
      Header: t("txt_role_name"),
      accessor: "permission",
      width: 200,
      className: "py-18 text-gray border-bottom-1 text-uppercase fw-semibold align-middle",
      Cell: ({ value, row }) => {
        return /* @__PURE__ */ React.createElement(React.Fragment, null, /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center py-8px" }, /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement("div", { className: `mb-1 ${row.depth !== 0 ? "ms-2" : ""}` }, value?.name))));
      }
    },
    {
      Header: t("txt_create"),
      accessor: "create",
      width: 100,
      className: "py-18 text-gray border-bottom-1 text-uppercase fw-semibold align-middle",
      Cell: ({ value, row }) => {
        return /* @__PURE__ */ React.createElement(SelectPermission, { row, value, permission: "create" });
      }
    },
    {
      Header: t("txt_edit"),
      accessor: "edit",
      width: 100,
      className: "py-18 text-gray border-bottom-1 text-uppercase fw-semibold align-middle",
      Cell: ({ value, row }) => {
        return /* @__PURE__ */ React.createElement(SelectPermission, { row, value, permission: "edit" });
      }
    },
    {
      Header: t("txt_delete"),
      accessor: "delete",
      width: 100,
      className: "py-18 text-gray border-bottom-1 text-uppercase fw-semibold align-middle",
      Cell: ({ value, row }) => {
        return /* @__PURE__ */ React.createElement(SelectPermission, { row, value, permission: "delete" });
      }
    }
  ];
  const SelectPermission = ({ row, value, permission }) => {
    const optionsList = [
      { label: "Allowed", value: "1" },
      { label: "Denied", value: "0" }
    ];
    const valueSelected = loadingUpdate[row?.original?.group_id + "-" + row?.original?.asset_id + "-" + permission]?.value;
    return /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center py-8px" }, /* @__PURE__ */ React.createElement("div", { className: "mb-1 position-relative" }, row?.depth !== 0 && (value?.toString() === "0" || value?.toString() === "1") && /* @__PURE__ */ React.createElement(React.Fragment, null, loadingUpdate[row?.original?.group_id + "-" + row?.original?.asset_id + "-" + permission]?.status === "saving" && /* @__PURE__ */ React.createElement(Spinner, { className: "spinner-overlay", size: "30px" }), /* @__PURE__ */ React.createElement(
      AesirXSelectExtended,
      {
        defaultValue: {
          label: optionsList?.find(
            (o) => o.value?.toString() === (valueSelected ? valueSelected?.toString() : value?.toString())
          )?.label,
          value: valueSelected ? valueSelected?.toString() : value?.toString()
        },
        options: optionsList,
        className: `fs-sm`,
        isDisabled: loadingUpdate[row?.original?.group_id + "-" + row?.original?.asset_id + "-" + permission]?.status === "saving" ? true : false,
        isBorder: true,
        arrowColor: "var(--dropdown-indicator-color)",
        size: "large",
        onChange: (data) => {
          handleChangeOption(
            row?.original?.entity,
            row?.original?.asset_id,
            row?.original?.group_id,
            data?.value,
            permission
          );
        }
      }
    ))));
  };
  const handleChangeOption = async (entity, asset_id, group_id, value, action) => {
    setLoadingUpdate({
      ...loadingUpdate,
      [group_id + "-" + asset_id + "-" + action]: { status: "saving", value }
    });
    await viewModel.handleFormPropsData(PERMISSION_FIELD.ASSET_ID, asset_id ?? 0);
    await viewModel.handleFormPropsData(PERMISSION_FIELD.GROUP_ID, group_id ?? 0);
    await viewModel.handleFormPropsData(PERMISSION_FIELD.ENTITY, entity ?? "");
    await viewModel.handleFormPropsData(PERMISSION_FIELD.VALUE, value ?? "1");
    await viewModel.handleFormPropsData(PERMISSION_FIELD.ACTION, action ?? "");
    await viewModel.update();
    setLoadingUpdate({
      ...loadingUpdate,
      [group_id + "-" + asset_id + "-" + action]: { status: "done", value }
    });
  };
  const selectPageHandler = (value) => {
    if (value != viewModel.successResponse.pagination.page) {
      viewModel.isLoading();
      viewModel.getListByFilter(
        "list[start]",
        (value - 1) * viewModel.successResponse.pagination.pageLimit
      );
    }
  };
  const selectShowItemsHandler = (value) => {
    viewModel.isLoading();
    viewModel.getListByFilter("list[limit]", value?.value);
  };
  const selectTabHandler = (value) => {
    viewModel.isLoading();
    if (value != "default") {
      viewModel.getListByFilter("filter[published]", value);
    } else {
      viewModel.getListByFilter("filter[published]", "");
    }
  };
  return /* @__PURE__ */ React.createElement("div", { className: "px-3 py-4" }, /* @__PURE__ */ React.createElement("div", { className: "mb-3 d-flex align-items-center justify-content-between" }, /* @__PURE__ */ React.createElement("h2", { className: "fw-bold" }, t("txt_left_menu_permissions"))), /* @__PURE__ */ React.createElement("div", { className: "mb-3" }, /* @__PURE__ */ React.createElement(
    Tabs,
    {
      defaultActiveKey: "permissionsList",
      id: "tab-setting",
      onSelect: (k) => selectTabHandler(k)
    },
    /* @__PURE__ */ React.createElement(Tab, { key: "permissionsList", eventKey: "permissionsList", title: t("txt_all_permission") })
  )), /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center justify-content-between gap-2 my-20" }, /* @__PURE__ */ React.createElement("div", null), /* @__PURE__ */ React.createElement("div", { className: "d-flex align-items-center" }, /* @__PURE__ */ React.createElement("div", { className: "text-gray me-2" }, t("txt_showing")), /* @__PURE__ */ React.createElement(
    AesirXSelectExtended,
    {
      defaultValue: {
        label: `${viewModel?.successResponse?.filters["list[limit]"]} ${t("txt_items")}`,
        value: viewModel?.successResponse?.filters["list[limit]"]
      },
      options: [...Array(9)].map((o, index) => ({
        label: `${(index + 1) * 10} ${t("txt_items")}`,
        value: (index + 1) * 10
      })),
      onChange: (o) => selectShowItemsHandler(o),
      className: `fs-sm bg-white shadow-sm rounded-2`,
      isBorder: true,
      placeholder: `Select`,
      arrowColor: "var(--dropdown-indicator-color)",
      size: "large"
    }
  ))), /* @__PURE__ */ React.createElement("div", { className: "bg-white rounded" }, viewModel?.successResponse?.state ? /* @__PURE__ */ React.createElement(
    A,
    {
      classNameTable: `bg-white rounded table-striped table`,
      columns: columnsTable,
      data: viewModel?.successResponse?.listPermission,
      pagination: viewModel?.successResponse?.pagination,
      selection: true,
      selectPage: selectPageHandler,
      hasSubRow: true
    }
  ) : /* @__PURE__ */ React.createElement(Spinner, null)));
});
var ListPermission_default = withTranslation()(withPermissionViewModel(ListPermission));

export {
  ListPermission_default
};
